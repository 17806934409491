import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfileData, UpdateUsersProfileData, UserNotificationSetting } from "../../redux/features/subscriptionSlice";
import { useAuth } from "../../context/authContext";
import FullPageLoader from "../../components/common/loaders/FullPageLoader";
import { message } from "antd";
import SettingModal from "./settingModal";
import FeatureCard from "../../components/features/FeatureCard";
import DashbordFeatures from "../../components/commonComponents/UnsubscribedFeatures";


export default function Settings() {
  const dispatch = useDispatch();
  const { userData, setUsers } = useAuth();
  const dropdownRef = useRef();
  let usersData = JSON.parse(localStorage.getItem("usersData"));
  const { usersProfileData, usersProfileLoader, notificationSettingData, notificationSettingLoader, updateProfileLoader, updateSettingLoader } = useSelector((state) => state.subscription);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currencyData, setCurrencyData] = useState({
    type: usersData?.currencyName,
    value: usersData?.currency ?? "$",
  });
  const [startEditAlert, setStartEditAlert] = useState({
    isOpenPopup: false,
    data: [],
  });

  const [confirmModel, setConfirmModel] = useState({
    isShow: false,
    notifcationData: {
      renewalEmail: false,
      renewalPush: false,
      expireEmail: false,
      expirePush: false,
    },
  });
  const [settingDate, setSettingData] = useState("");
  const [notificationData, setNotificationData] = useState({
    renewalEmail: usersProfileData?.renewalEmail === 1 ? true : false,
    renewalPush: usersProfileData?.renewalPush === 1 ? true : false,
    expireEmail: usersProfileData?.expireEmail === 1 ? true : false,
    expirePush: usersProfileData?.expirePush === 1 ? true : false,
  });

  useEffect(() => {
    dispatch(GetUserProfileData());
    dispatch(UserNotificationSetting());
  }, []);

  useEffect(() => {
    setCurrencyData({
      type: usersData?.currencyName,
      value: usersData?.currency ?? "$",
    });
  }, [modalOpen]);

  useEffect(() => {
    if (modalOpen || confirmModel?.isShow || deleteModal || startEditAlert?.isOpenPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modalOpen, confirmModel?.isShow, deleteModal, startEditAlert?.isOpenPopup]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdown]);

  useEffect(() => {
    setNotificationData({
      renewalEmail: usersProfileData?.renewalEmail === 1 ? true : false,
      renewalPush: usersProfileData?.renewalPush === 1 ? true : false,
      expireEmail: usersProfileData?.expireEmail === 1 ? true : false,
      expirePush: usersProfileData?.expirePush === 1 ? true : false,
    });
    setConfirmModel({
      ...confirmModel,
      notifcationData: {
        renewalEmail: usersProfileData?.renewalEmail === 1 ? true : false,
        renewalPush: usersProfileData?.renewalPush === 1 ? true : false,
        expireEmail: usersProfileData?.expireEmail === 1 ? true : false,
        expirePush: usersProfileData?.expirePush === 1 ? true : false,
      },
    });
  }, [usersProfileData]);

  const handleChange = (e) => {
    setConfirmModel({ isShow: true, notifcationData: { ...confirmModel?.notifcationData, [e?.target?.name]: e?.target?.checked } });
    if (e?.target?.name === "renewalEmail") {
      if (e?.target?.checked) {
        setSettingData("enable email notifications for renewal updates?");
      } else {
        setSettingData("disable email notifications for renewal updates?");
      }
    } else if (e?.target?.name === "renewalPush") {
      if (e?.target?.checked) {
        setSettingData("enable push notifications for renewal updates?");
      } else {
        setSettingData("disable push notifications for renewal updates?");
      }
    } else if (e?.target?.name === "expireEmail") {
      if (e?.target?.checked) {
        setSettingData("enable email notifications for expiry updates?");
      } else {
        setSettingData("disable email notifications for expiry updates?");
      }
    } else if (e?.target?.name === "expirePush") {
      if (e?.target?.checked) {
        setSettingData("enable push notifications for expiry updates?");
      } else {
        setSettingData("disable push notifications for expiry updates?");
      }
    }
  };

  const handleSubmitSave = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("renewalEmail", confirmModel?.notifcationData?.renewalEmail);
    formData.append("renewalPush", confirmModel?.notifcationData?.renewalPush);
    formData.append("expireEmail", confirmModel?.notifcationData?.expireEmail);
    formData.append("expirePush", confirmModel?.notifcationData?.expirePush);
    dispatch(UpdateUsersProfileData({ id: usersProfileData?.uid, body: formData }))
      .then((res) => {
        console.log("ressssssssssssssssssssss", res);
        setNotificationData(confirmModel?.notifcationData);
        setLoading(false);
        setUsers(res?.payload);
        setConfirmModel({ isShow: false });
        dispatch(GetUserProfileData());
        message.success("Notification settings updated successfully");
      })
      .catch((e) => {
        setLoading(false);
        message.error("Failed to update profile");
      });
  };

  const handleSubmitCurrency = () => {
    const formData = new FormData();
    formData.append("currency", currencyData?.value);
    formData.append("currencyName", currencyData?.type);
    dispatch(UpdateUsersProfileData({ id: usersProfileData?.uid, body: formData }))
      .then((res) => {
        setDropdown(false);
        setDisabled(true);
        setDeleteModal(false);
        setModalOpen(false);
        dispatch(GetUserProfileData());
        setCurrencyData({
          type: "",
          value: "",
        });
      })
      .catch((e) => {
        setLoading(false);
        message.error("Failed to update profile");
      });
  };

  const handleCurrencyType = (e, type, value) => {
    setDropdown(false);
    setDisabled(false);
    setCurrencyData({
      type: type,
      value: value,
    });
  };

  return !usersData?.isUserSubscribed ? (
    <div className="flex items-center justify-center h-full">
      <div className="unsubscribedContainerWrapper">
        <div className="unsubscribedContainer">
        
          <FeatureCard feature={DashbordFeatures} />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div>
        <div className=" pb-5 border-b border-solid border-borderColor1">
          <h2 className="font-medium text-xl text-black300">Settings</h2>
        </div>
        <div className="bg-white p-5 rounded-xl mt-5">
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-base mobile:text-xs text-gray600 font-medium text-left border-b border-solid border-borderColor1">
                <th className="p-3 mobile:p-2">Notification Type</th>
                <th className="p-3 mobile:p-2 w-32 mobile:w-16">Email </th>
                <th className="p-3 mobile:p-2 w-32 mobile:w-16">Push</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-base mobile:text-xs text-black300 font-medium text-left border-b border-solid border-borderColor1">
                <td className="p-3 mobile:p-2">Renewal Updates</td>
                <td className="p-3 mobile:p-2">
                  <label class="switch">
                    <input type="checkbox" name="renewalEmail" checked={notificationData?.renewalEmail} onChange={(e) => handleChange(e)} />
                    <span class="slider"></span>
                  </label>
                </td>
                <td className="p-3 mobile:p-2">
                  <label class="switch">
                    <input type="checkbox" name="renewalPush" checked={notificationData?.renewalPush} onChange={(e) => handleChange(e)} />
                    <span class="slider"></span>
                  </label>
                </td>
              </tr>
              <tr className="text-base mobile:text-xs text-black300 font-medium text-left ">
                <td className="p-3 mobile:p-2">Expiry Updates</td>
                <td className="p-3 mobile:p-2">
                  <label class="switch">
                    <input type="checkbox" name="expireEmail" checked={notificationData?.expireEmail} onChange={(e) => handleChange(e)} />
                    <span class="slider"></span>
                  </label>
                </td>
                <td className="p-3 mobile:p-2 ">
                  <label class="switch">
                    <input type="checkbox" name="expirePush" checked={notificationData?.expirePush} onChange={(e) => handleChange(e)} />
                    <span class="slider"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="bg-white p-5 rounded-xl mt-5">
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-base mobile:text-xs text-gray600 font-medium text-left border-b border-solid border-borderColor1">
                <th className="p-3 flex gap-2 mobile:p-2">
                  Alert for{" "}
                  <img className="cursor-pointer" src={"/assets/pencilicon.svg"} width={15} height={15} onClick={() => setStartEditAlert({ ...startEditAlert, isOpenPopup: true, data: notificationSettingData.map(({ uid, ...rest }) => rest) })} />
                </th>
                <th className="p-3 mobile:p-2 w-32 mobile:w-16">Days </th>
              </tr>
            </thead>
            <tbody>
              {notificationSettingData?.map((item, index) => {
                return (
                  <tr className="text-base mobile:text-xs text-black300 font-medium text-left border-b border-solid border-borderColor1">
                    <td className="p-3 mobile:p-2 w-[200px]">{item?.type}</td>
                    <td className="p-3 mobile:p-2">{item?.days} Days</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h2 className="font-medium text-xl text-black300 mt-5">Currency</h2>
        <div className="bg-white p-5 rounded-xl mt-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <p className="text-base mobile:text-xs font-medium text-black300">System Currency</p>
              <i class="fa-solid fa-pen-to-square cursor-pointer mobile:text-xs" onClick={() => setModalOpen(!modalOpen)}></i>
            </div>
            <p className="text-base mobile:text-xs font-medium text-black300">{usersData?.currencyName}</p>
          </div>
        </div>
        {modalOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center z-[9999]">
            <div className="bg-white rounded-xl mobile:rounded-md w-[580px] mobile:w-[calc(100%-20px)]">
              <div className="border-solid border-b border-borderColor1 p-4 flex items-center justify-between">
                <p className="text-xl mobile:text-base font-medium text-black300">Currency</p>
                <i class="fa-solid fa-xmark cursor-pointer text-xl" onClick={() => setModalOpen(false)}></i>
              </div>
              <div className="p-4">
                <label className="text-sm text-gray700 font-medium block pb-2">System currency</label>
                <div ref={dropdownRef} className="relative">
                  <div onClick={() => setDropdown(!dropdown)} className="w-full cursor-pointer bg-[#f5f5f5] p-3 text-sm flex items-center justify-between font-medium text-black300 rounded">
                    <span className="text-sm">{currencyData?.type}</span>
                    <i class="fa-solid fa-chevron-down text-black300 cursor-pointer"></i>
                  </div>
                  <div className={dropdown ? "cus-dropdown-design show" : "cus-dropdown-design hide"}>
                    <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={(e) => handleCurrencyType(e, "₹-Rupees", "₹")}>
                      ₹-Rupees
                    </span>
                    <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={(e) => handleCurrencyType(e, "AED-Dirham", "AED")}>
                      AED-Dirham
                    </span>
                    <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={(e) => handleCurrencyType(e, "$-Dollar", "$")}>
                      $-Dollar
                    </span>
                    <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={(e) => handleCurrencyType(e, "£-Pound", "£")}>
                      £-Pound
                    </span>
                    <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={(e) => handleCurrencyType(e, "€-Euro", "€")}>
                      €-Euro
                    </span>
                    <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={(e) => handleCurrencyType(e, "¥-Yen", "¥")}>
                      ¥-Yen
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-5 py-3 flex items-center justify-end">
                <button disabled={disabled} className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md primary-gradient-bg" onClick={() => setDeleteModal(true)}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        {confirmModel?.isShow && (
          <div className="global-modal" style={{ zIndex: "999" }}>
            <div className="bg-white rounded-xl w-[510px] p-5  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
              {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
              <div className="flex justify-between items-center">
                <p className="text-lg text-black300 font-medium text-center mb-5 max-w-[90%] mx-auto">Are you sure you want to {settingDate}</p>
              </div>

              <div className="flex justify-end gap-3 pt-3">
                <button
                  className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
                  onClick={() => {
                    setConfirmModel({ ...confirmModel, isShow: false });
                  }}
                >
                  Cancel
                </button>
                <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleSubmitSave()}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        {deleteModal && (
          <div className="global-modal" style={{ zIndex: "999" }}>
            <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
              {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
              <div className="flex justify-between items-center">
                <p className="text-lg text-black300 font-medium text-center mb-5">Are you sure you want to make these changes?</p>
              </div>

              <div className="flex justify-end gap-3 pt-3">
                <button
                  className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  Cancel
                </button>
                <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleSubmitCurrency()}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {(loading || usersProfileLoader || notificationSettingLoader || updateSettingLoader || updateProfileLoader) && <FullPageLoader />}
        {startEditAlert?.isOpenPopup && <SettingModal startEditAlert={startEditAlert} setStartEditAlert={setStartEditAlert} />}
      </div>
    </>
  );
}
