import React, { useEffect, useState } from "react";
import SubscriptionsCard from "../../components/SubscriptionsCard";
import { array } from "yup/lib/locale";
import NoDataFound from "../../assets/images/My-Subscription-no-data.webp";
import { useDispatch, useSelector } from "react-redux";
import { GetALLSubscriptions, GetUserCurrentCategory, GetUserCurrentSubscriptions, UpdateSubscription } from "../../redux/features/subscriptionSlice";
import { useNavigate } from "react-router";
import SubscriptiondetailsModal from "../../components/subscriptiondetailsModal";
import { getUser, useAuth } from "../../context/authContext";
import { message } from "antd";
import FullPageLoader from "../../components/common/loaders/FullPageLoader";
import DashbordFeatures from "../../components/commonComponents/UnsubscribedFeatures";
import FeatureCard from "../../components/features/FeatureCard";

const MySubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useAuth();
  const { usersCurrentSub, usersCurrentCategory, usersCurrentSubLoader } = useSelector((state) => state.subscription);
  const { allSubscriptions } = useSelector((state) => state.subscription);
  // const [categoryArray, setCategoryArray] = useState([])
  const [categoryArray, setCategoryArray] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "All",
  });
  const userProfileData = JSON.parse(localStorage.getItem("usersData"));

  const { isAuthenticate, isUserSubscribed } = useAuth();
  const [isUserSubscrib, setIsUserSubscrib] = useState(isUserSubscribed);

  const [activeTab, setActiveTab] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: "",
    sub_description: "",
    cost: "",
    plan_name: "monthly",
    start_date: "",
    end_date: "",
    notes: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: "",
  });
  
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();

    let data = { id: newSubsData?.id, body: newSubsData };
    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }
    dispatch(UpdateSubscription(data))
      .then((res) => {
        message.success(res?.message || "Subscription Updated Successfully");
        dispatch(GetUserCurrentSubscriptions({ status: activeTab }));
        handleClose();
      })
      .catch((err) => {
        message.error(err);
      });
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      end_date: "",
      notes: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
      sub_id: null,
    });
  };

  const handleSubsDetails = (e) => {
    if (e.target.name === "email") {
      if (e.target.value.trim() !== "") {
        setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
      } else {
        setNewSubsData({ ...newSubsData, [e.target.name]: "" });
        setError({ ...error, [e.target.name]: "Please enter email" });
      }
    } else {
      setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value?.trimStart() });
      setError({ ...error, [e.target.name]: "" });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModel = (item) => {
    setOpen(true);
    setNewSubsData({
      ...newSubsData,
      active: item.active,
      sub_id: item.sub_id,
      sub_title: item?.sub_id,
      free_trial_days: item.free_trial_days,
      cost: item?.cost,
      id: item?.id,
      sub_type: item?.type,
      start_date: item?.start_date,
      end_date: item?.end_date,
      notes: item?.notes,
      isPaymentMethod: item?.isPaymentMethod,
      card_no: item?.card_no,
      plan_variety: item?.plan_variety,
      plan_name: item?.plan_name,
      paypal_email: item?.paypal_email ?? "",
      name: item?.name,
      email: item?.email,
      pay_method: item?.pay_method,
    });
  };

  const handleClicked = (item) => {
    setSelectedCategory(item);
    if (item?.name != selectedCategory?.name) {
      dispatch(GetUserCurrentSubscriptions({ status: activeTab, category: item?.categoryId ? item.categoryId : null }));
    }
  };

  const handleChangeTab = (data) => {
    setActiveTab(data);
    setSelectedCategory({ name: "All" });
    dispatch(GetUserCurrentSubscriptions({ status: data }));
    dispatch(GetUserCurrentCategory(data));
  };

  useEffect(() => {
    dispatch(GetUserCurrentSubscriptions({ status: activeTab }));
    dispatch(GetALLSubscriptions());
    dispatch(GetUserCurrentCategory(activeTab));
    async function fetchData() {
      const authData = await getUser();
      setIsUserSubscrib(authData?.isUserSubscribed);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const categoryCount = {};

    // Extract the category information and accumulate the usedTime
    usersCurrentCategory?.plans?.forEach((plan) => {
      plan?.categories?.forEach((category, index) => {
        const categoryId = plan.categoriesId[index]; // Get the corresponding categoryId

        if (categoryCount[category]) {
          categoryCount[category].usedTime++;
        } else {
          categoryCount[category] = {
            usedTime: 1,
            categoryId: categoryId, // Assign the correct categoryId
          };
        }
      });
    });

    // Transform the categoryCount object into an array of objects
    const categoryArray = Object.keys(categoryCount).map((key) => {
      return {
        name: key,
        usedTime: categoryCount[key].usedTime,
        categoryId: categoryCount[key].categoryId,
      };
    });
    setCategoryArray(categoryArray);
  }, [usersCurrentCategory]);

  return !userProfileData?.isUserSubscribed ? (
    <div className="flex h-screen items-center justify-center">
      <div className="unsubscribedContainerWrapper">
        <div className="unsubscribedContainer">
       
          <FeatureCard feature={DashbordFeatures} />
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="flex items-center justify-between pb-4">
        <h2 className="font-medium text-xl text-black300">Subscriptions</h2>
        <button className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md primary-gradient-bg" onClick={() => navigate("/subscription-list")}>
          Add Subscription
        </button>
      </div>
      <div className="flex items-center gap-9 pb-2 border-b border-solid border-borderColor1">
        <div className="cursor-pointer" onClick={() => handleChangeTab(true)}>
          <span className={`text-sm custom-tab relative font-medium text-black300 ${activeTab ? "active-custom-tab" : ""}`}>Active</span>
        </div>
        <div className="cursor-pointer" onClick={() => handleChangeTab(false)}>
          <span className={`text-sm custom-tab   relative font-medium text-black300 ${!activeTab ? "active-custom-tab" : ""} `}>Inactive</span>
        </div>
      </div>
      <div className="grid grid-cols-[270px_1fr] pt-5 tab:grid-cols-[210px_1fr] gap-5 mobile:grid-cols-1">
        <div>
          <div className="bg-white rounded-lg p-4 border border-solid border-borderColor1 max-h-[78vh] overflow-y-auto mobile:max-h-[39vh]">
            <p className="text-base font-medium text-gray600 mb-3">Categories</p>
            <div
              className={`flex items-center justify-between p-2 rounded-md hover:bg-grayBackground mb-1 cursor-pointer transition ease-in-out ${selectedCategory?.name === "All" ? "bg-grayBackground mb-1 cursor-pointer transition ease-in-out" : ""}`}
              onClick={() => handleClicked({ name: "All" })}
            >
              <p className="text-sm text-black200 font-medium">All</p>
              <p className="text-xs text-gray600 font-medium w-6 h-6 rounded-full object-cover flex justify-center items-center bg-[#d0ee11]">{usersCurrentCategory?.plans?.length ?? 0}</p>
            </div>
            {categoryArray?.map((item) => {
              return (
                <div
                  className={`flex items-center justify-between p-2 rounded-md hover:bg-grayBackground mb-1 cursor-pointer transition ease-in-out ${
                    selectedCategory?.name === item?.name ? "bg-[#f0efed] mb-1 cursor-pointer transition ease-in-out" : ""
                  }`}
                  onClick={() => handleClicked(item)}
                >
                  <p className="text-sm text-black200 font-medium">{item?.name}</p>
                  <p className="text-xs text-gray600 font-medium w-6 h-6 rounded-full object-cover flex justify-center items-center bg-[#d0ee11]">{item?.usedTime}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          {usersCurrentSubLoader ? (
            <>
              <FullPageLoader />
              <div className="flex  gap-5 flex-wrap mobile:grid mobile:grid-cols-2 mobile:gap-3">
                {usersCurrentSub.map((item, index) => {
                  return <SubscriptionsCard item={item} index={index} handleOpenModel={handleOpenModel} activeTab={activeTab} />;
                })}
              </div>
            </>
          ) : usersCurrentSub?.length > 0 ? (
            <div className="flex  gap-5 flex-wrap mobile:grid mobile:grid-cols-2 mobile:gap-3">
              {usersCurrentSub.map((item, index) => {
                return <SubscriptionsCard item={item} index={index} handleOpenModel={handleOpenModel} activeTab={activeTab} />;
              })}
            </div>
          ) : (
            <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center mobile:gap-2 mobile:grid mobile:grid-cols-1 p-3 rounded-[20px] w-full">
              <div className="mobile:flex mobile:justify-center">
              <img src={NoDataFound} alt="No Data Found" width={200} height={200} />
              </div>
              <p className="text-xl mobile:text-lg mobile:text-center font-medium text-black">No subscription found</p>
              <p className="mobile:text-sm mobile:text-center mobile:mb-5">You have not added any subscripitons yet</p>
            </div>
          )}
        </div>
      </div>

      {open && (
        <SubscriptiondetailsModal
          open={open}
          userData={userData}
          setOpen={setOpen}
          handleClose={handleClose}
          handleSubsDetails={handleSubsDetails}
          setNewSubsData={setNewSubsData}
          newSubsData={newSubsData}
          handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
          error={error}
          setError={setError}
          allSubscriptions={allSubscriptions}
          setIsEditSubscription={true}
        />
      )}
    </div>
  );
};

export default MySubscription;
