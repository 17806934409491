import React, { useEffect, useState } from "react";
import styles from "./notification.module.css";
import trstyles from "../Transactions/transaction.module.css";
import { message, Select } from "antd";
import NetflixIcon from "../../../assets/icons/netflix-xs.svg";
import NotificationIcon from "../../../assets/images/NotificationIcon.webp";
import DotIcon from "../../../assets/icons/three-dot.svg";
import NotificationBody from "./NotificationBody";
import { deleteNotification, showNotifications } from "../../../redux/features/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";

import NoDataFound from "../../../assets/images/notification-no-data.webp";

import DashbordFeatures from "../../commonComponents/UnsubscribedFeatures";
import { getUser, useAuth } from "../../../context/authContext";
import FeatureCard from "../../features/FeatureCard";
import { useNavigate } from "react-router";
import FullPageLoader from "../../common/loaders/FullPageLoader";
import useDebounce from "../../../hooks/useDebounce";
import Pagination from "../../commonPagination/pagination";
import moment from "moment";

function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("usersData"));
  const [searchedNotifications, setSearchedNotifications] = useState([]);
  const [search, setSearch] = useState("");
  const { isAuthenticate, isUserSubscribed } = useAuth();
  const [isUserSubscrib, setIsUserSubscrib] = useState(isUserSubscribed);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10);
  const [showNotes, setShowNotes] = useState({
    isShow: false,
    data: "",
  });
  const [notificationDays, setNotificationDays] = useState({
    days: 30,
    notificationFor: "Last 30 Days"
  });
  // const [days, setDays] = useState("");
  const { notifications, deleteNotificationLoader, notificationsLoader,notificationCount } = useSelector((state) => ({
    ...state.subscription,
  }));
  const [deleteModel, setDeleteModel] = useState({
    isShow: false,
    id: null,
  });

  const debouncedSearchTerm = useDebounce(search.trim(), 500);

  useEffect(() => {
    dispatch(
      showNotifications({
        query: debouncedSearchTerm,
        body: { days: notificationDays?.days, page: page, limit: limit },
      })
    );
    async function fetchData() {
      const authData = await getUser();
      setIsUserSubscrib(authData?.isUserSubscribed);
    }
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(
      showNotifications({
        query: debouncedSearchTerm,
        body: { days: notificationDays?.days, page: page, limit: limit },
      })
    );
  }, [debouncedSearchTerm, page]);

  const handleOnSearch = (e) => {
    e.preventDefault();
    setPage(1)
    if (e.target.value.trim() !== "") {
      setSearch(e.target.value);
      } else {
      setSearch("");
    }
  };

  const handleDeleteNotification = () => {
    dispatch(deleteNotification({ id: deleteModel?.id }))
      .then((res) => {
        dispatch(
          showNotifications({
            query: debouncedSearchTerm,
            body: { days: notificationDays?.days, page: page, limit:limit },
          })
        );
        setDeleteModel({
          isShow: false,
          id: null,
        });
        message.success(res?.message || "Notification Deleted Successfully");
      })
      .catch((e) => {
        message.error("Failed to update profile");
      });
  };

  const handleSelectDays = (e) =>{
    if(e.target.value === "Today"){
      setNotificationDays({ days: 1, notificationFor: e.target.value });
      setPage(1)
      dispatch(
        showNotifications({
          query: debouncedSearchTerm,
          body: { days: 1, page: 1, limit:limit },
        })
      );
    }
    else if(e.target.value === "Last 7 Days"){
      setNotificationDays({ days: 7, notificationFor: e.target.value });
      setPage(1)
      dispatch(
        showNotifications({
          query: debouncedSearchTerm,
          body: { days: 7, page: 1, limit:limit },
        })
      );
    }
    else if(e.target.value === "Last 15 Days"){
      setNotificationDays({ days: 15, notificationFor: e.target.value });
      setPage(1)
      dispatch(
        showNotifications({
          query: debouncedSearchTerm,
          body: { days: 15,page: 1, limit: limit },
        })
      );
    }
    else if(e.target.value === "Last 30 Days"){
      setNotificationDays({ days: 30, notificationFor: e.target.value });
      setPage(1)
      dispatch(
        showNotifications({
          query: debouncedSearchTerm,
          body: { days: 30, page: 1, limit: limit },
        })
      );
    }
  }

  return !userData?.isUserSubscribed ? (
    <div className="flex h-screen items-center justify-center">
      <div className="unsubscribedContainerWrapper">
        <div className="unsubscribedContainer">
         
          <FeatureCard feature={DashbordFeatures} />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="pb-5 border-b border-solid border-borderColor1">
        <h2 className="text-xl text-black300 mobile:text-sm font-medium ">Notification</h2>
      </div>
      <div className="flex items-center justify-between pb-4 pt-5 mobile:grid mobile:grid-cols-1 mobile:gap-3">
        <div className="flex items-center gap-4 mobile:grid mobile:grid-cols-1">
          <div className="w-[344px] mobile:w-full">
            <input className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white" value={search} type="text" placeholder="Search" onChange={(e) => handleOnSearch(e)} />
          </div>
          <div className="w-[110px] mobile:w-full">
            <select className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white"
            onChange={handleSelectDays}
            value={notificationDays?.notificationFor}
            >
              <option>Today</option>
              <option>Last 7 Days</option>
              <option>Last 15 Days</option>
              <option>Last 30 Days</option>
            </select>
          </div>
        </div>
        <p className="text-base font-medium text-gray700 cursor-pointer underline" onClick={() => navigate("/setting")}>
          Manage
        </p>
      </div>

      {/* <div className={styles.header}>
        <div className={trstyles.searchContainer}>
          <input
            type="text"
            className={trstyles.searchfeild}
            placeholder="search..."
            onChange={handleSearch}
            value={serchedText}
          />
          <img
            src="/assets/searchicon.svg"
            alt="searchicon"
            style={{ width: "16px", height: "16px" }}
          />
        </div>
        <Select
          className="select-class"
          style={{ border: "1px solid rgba(136, 136, 136, 0.4)" }}
          labelInValue
          defaultValue={{
            value: 30,
            label: (
              <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                30 days
              </p>
            ),
          }}
          onChange={handleChange}
          options={[
            {
              value: 60,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  60 days
                </p>
              ),
            },
            {
              value: 30,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  30 days
                </p>
              ),
            },
          ]}
        />
      </div> */}

      <div className="bg-white py-4 pt-0 px-6 rounded-2xl mobile:p-3 h-[calc(100vh-278px)] overflow-auto">
        <p className="text-sm pt-4 font-bold text-black300">{notificationDays?.notificationFor}</p>
        {notifications?.length > 0 ? (
          notifications?.map((item, index) => {
            return (
              <div className="py-4 border-solid border-b border-borderColor5 flex mobile:grid mobile:grid-cols-1 mobile:gap-0 items-center justify-between">
                <div className="flex gap-4">
                  <img className="w-12 h-12 min-w-12 block object-cover rounded" src={item?.img_url} alt="NetflixIcon" />
                  <div>
                    <div className="flex items-center gap-3">
                      <p className="text-black300 font-bold opacity-60 text-sm">{item?.title}</p>
                      {item?.notes && (
                        <div className="p-1 cursor-pointer flex items-center" onClick={() => setShowNotes({ isShow: true, data: item?.notes })}>
                          <i class="fa-solid text-sm fa-pen-nib"></i>
                          {/* <i class="fa-solid text-sm fa-eye"></i> */}
                        </div>
                      )}
                    </div>
                    <p className="text-black300 font-normal opacity-60 text-sm">{item?.description ?? "-"}</p>
                  </div>
                </div>
                <div className="flex items-center gap-4 mobile:gap-3 mobile:justify-end pr-3 mobile:pt-2">
                  <p className="text-sm font-medium text-black300">{ moment(item?.created_at).utc().format('DD/MM/YYYY HH:mm')}</p>
                  <i onClick={() => setDeleteModel({ isShow: true, id: item?.id })} class="fa-solid fa-trash text-red-600 cursor-pointer"></i>
                </div>
              </div>
            );
          })
        ) : (
          <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center mobile:gap-2 mobile:grid mobile:grid-cols-1 p-3 rounded-[20px] w-full h-[90%] mobile:h-auto">
           <div className="mobile:flex mobile:items-center mobile:justify-center">
           <img src={NoDataFound} alt="No Data Found" width={200} height={200} />
           </div>
            <p className="text-xl mobile:text-lg mobile:text-center font-medium text-black">No notification found</p>
            <p className="mobile:text-center">You have not any notifiaction yet</p>
          </div>
        )}
      </div>
      <Pagination pages={Math.ceil(notificationCount / 10)} current={page} onClick={setPage} />

      {deleteModel?.isShow && (
        <div className="global-modal" style={{ zIndex: "999" }}>
          <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:overflow-auto">
            {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
            <div className="flex justify-between items-center">
              <p className="text-lg text-black300 font-medium text-center mb-5">Are you sure you want to delete this notification?</p>
            </div>

            <div className="flex justify-end gap-3 pt-3">
              <button
                className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
                onClick={() => {
                  setDeleteModel({ isShow: false, id: null });
                }}
              >
                Cancel
              </button>
              <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleDeleteNotification()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showNotes?.isShow && (
        <div className="global-modal" style={{ zIndex: "999" }}>
          <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
            <div className="flex text-black justify-between pb-4 border-b border-solid border-borderColor1">
              <p className="text-lg font-medium text-black300">Additional Notes</p>
              <button onClick={() => setShowNotes({ isShow: false, data: "" })}><i className="fa-solid fa-xmark cursor-pointer text-xl"></i></button>
            </div>
            {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
            <div className="flex justify-between items-center py-4">
              <p className="text-base text-gray600 font-normal">{showNotes?.data}</p>
            </div>
          </div>
        </div>
      )}

      {/* <NotificationBody
        notifications={
          searchedNotifications.length > 0
            ? searchedNotifications
            : notifications
        }
      /> */}
      {(deleteNotificationLoader || notificationsLoader) && <FullPageLoader />}
    </>
  );
}

export default Notifications;
