import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import styles from "./transaction.module.css";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DatePicker from "react-datepicker";

import NoDataFound from "../../../assets/images/My-Subscription-no-data.webp";
import moment from "moment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { allTransactionsData, deleteTransaction, filterSearch } from "../../../redux/features/subscriptionSlice";
import useDebounce from "../../../hooks/useDebounce";
import FullPageLoader from "../../common/loaders/FullPageLoader";
import Skeleton from "react-loading-skeleton";
import DeleteTransactionModel from "../../deleteTransactionModel";
import { message } from "antd";
import { getUser, useAuth } from "../../../context/authContext";

import DashbordFeatures from "../../commonComponents/UnsubscribedFeatures";
import FeatureCard from "../../features/FeatureCard";
import Pagination from "../../commonPagination/pagination";

function Transactions() {
  const dispatch = useDispatch();
  const { allUserTransactionsData, allUserTransactionsCount, allUserTransactionsLoader, deleteTransactionLoader } = useSelector((state) => state.subscription);
  const userData = JSON.parse(localStorage.getItem("usersData"));

  const [search, setSearch] = useState("");
  const { isAuthenticate, isUserSubscribed } = useAuth();
  const usersData = JSON.parse(localStorage.getItem("usersData"));
  const [isUserSubscrib, setIsUserSubscrib] = useState(isUserSubscribed);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [deleteModel, setDeleteModel] = useState({
    isShow: false,
    id: null,
  });

  const [isCutomDate, setIsCostumDate] = useState({
    isShowCalender: false,
    // startDate: null,
    // endDate: null,
    startDate: moment().subtract(30, "days").toDate(),
    endDate: moment().toDate(),
  });

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setIsCostumDate({ ...isCutomDate, startDate: start, endDate: end });
    setPage(1);
    if (start && end) {
      dispatch(
        allTransactionsData({
          body: { search: debouncedSearchTerm, start_date: moment(start).format("YYYY-MM-DD"), end_date: moment(end).format("YYYY-MM-DD"), page: 1, limit: limit },
        })
      );
    }
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    if (e.target.value.trim() !== "") {
      setSearch(e.target.value);
      setPage(1);
    } else {
      setSearch("");
    }
  };

  const handleDeleteSubscription = () => {
    let data = { id: deleteModel?.id };
    dispatch(deleteTransaction(data))
      .then((res) => {
        message.success(res?.message || "Transaction Deleted Successfully");
        setDeleteModel({ isShow: false, id: null });
        dispatch(
          allTransactionsData({
            body: { search: debouncedSearchTerm, start_date: moment(isCutomDate?.startDate).format("YYYY-MM-DD"), end_date: moment(isCutomDate?.endDate).format("YYYY-MM-DD"), page: page, limit: limit },
          })
        );
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleDeleteButton = (data) => {
    setDeleteModel({ isShow: true, id: data.id });
  };

  const debouncedSearchTerm = useDebounce(search.trim(), 500);

  useEffect(() => {
    dispatch(
      allTransactionsData({
        body: { search: debouncedSearchTerm, start_date: moment(isCutomDate?.startDate).format("YYYY-MM-DD"), end_date: moment(isCutomDate?.endDate).format("YYYY-MM-DD"), page: page, limit: limit },
      })
    );
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    dispatch(
      allTransactionsData({
        body: { search: debouncedSearchTerm, start_date: moment().subtract(30, "days").format("YYYY-MM-DD"), end_date: moment().format("YYYY-MM-DD"), page: page, limit: limit },
      })
    );
    async function fetchData() {
      const authData = await getUser();
      setIsUserSubscrib(authData?.isUserSubscribed);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (deleteModel?.isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [deleteModel?.isShow]);

  return !usersData?.isUserSubscribed ? (
    <div className="flex h-screen items-center justify-center">
      <div className="unsubscribedContainerWrapper">
        <div className="unsubscribedContainer">
     
          <FeatureCard feature={DashbordFeatures} />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex items-center justify-between pb-5 border-b border-solid border-borderColor1">
        <h2 className="font-medium text-xl text-black300">Transaction History</h2>
      </div>
      <div className="py-5 flex items-center gap-5 mobile:py-2 mobile:grid mobile:grid-cols-1 mobile:gap-3">
        <div className="w-[320px] relative mobile:w-full">
          <input type="text" placeholder="Search..." value={search} onChange={handleOnSearch} className="bg-white p-2 text-sm placeholder:text-sm pr-9 flex items-center justify-between font-medium text-black300 rounded w-full" />
          <div className="absolute right-4 top-[50%] flex items-center translate-y-[-50%]">
            <img src="/assets/searchicon.svg" alt="searchicon" className="w-3 h-3 block" />
          </div>
        </div>
        <DatePicker
          dateFormat="dd/MMM/yyyy"
          placeholderText="Select a date"
          fixedHeight
          startDate={isCutomDate.startDate}
          endDate={isCutomDate.endDate}
          onChange={handleDateChange}
          maxDate={moment().toDate()}
          selectsRange
          className="w-[240px] mobile:w-full cursor-pointer bg-white p-2 text-sm placeholder:text-sm flex items-center justify-between font-medium text-black300 rounded"
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>

      <div className="w-full bg-white rounded-xl p-4 overflow-auto min-h-[calc(100vh-289px)]">
        {allUserTransactionsLoader ? (
          <>
            <FullPageLoader />
            <Skeleton height={30} count={10} />
          </>
        ) : allUserTransactionsData?.length > 0 ? (
          <table className="w-full border-collapse ">
            <thead>
              <tr className="border-solid border-b border-borderColor1">
                <th className="text-base mobile:text-sm mobile:p-1 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Service Name</th>
                <th className="text-base mobile:text-sm mobile:p-1 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Date</th>
                <th className="text-base mobile:text-sm mobile:p-1 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Amount</th>
                <th className="text-base mobile:text-sm mobile:p-1 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">
                  Total ({userData?.currency ?? "$"} {allUserTransactionsData.reduce((acc, sub) => acc + (sub.totalCosts || sub.totalCost || 0), 0)?.toFixed(2) ?? 0.0})
                </th>
                <th className="text-base mobile:text-sm mobile:p-1 mobile:whitespace-nowrap font-medium text-left text-black400 p-2">Action</th>
              </tr>
            </thead>

            <tbody>
              {allUserTransactionsData.map((item, index) => {
                return (
                  <tr className=" border-solid border-b border-borderColor1 hover:bg-inputBackground">
                    <td className="text-sm font-normal text-left text-black400 p-2">
                      <div className="flex items-center gap-4">
                        <img className="w-8 h-8 rounded-md block object-cover shadow-4xl" src={item?.sub_logo} />
                        <p className="mobile:whitespace-nowrap mobile:min-w-[160px]">{item?.sub_name}</p>
                      </div>
                    </td>
                    <td className="text-sm font-normal text-left text-black400 p-2 whitespace-nowrap">{moment(item?.pay_date).format("DD-MMM-YYYY")}</td>

                    <td className="text-sm font-medium text-left text-black400 p-2">
                      {" "}
                      {item?.type === "free" ? (
                        <p className=""> Free Trial</p>
                      ) : (
                        <p className="">
                          {item?.type === "" ? "free" : userData?.currency ?? "$"}
                          {item?.cost ?? 0.0}
                        </p>
                      )}
                    </td>
                    <td className="text-sm font-normal text-left text-black400 p-2">
                      <button className=" text-gray700 text-sm py-1 font-medium px-5 rounded  cursor-auto flex pl-1 flex-col">
                        {item?.type === "free" ? (
                          <p className=" text-black200 "> Free Trial</p>
                        ) : (
                          <p className=" text-black200 ">
                            {userData?.currency ?? "$"}
                            {item?.totalCosts?.toFixed(2)}
                          </p>
                        )}
                      </button>
                    </td>
                    <td className="text-sm font-normal text-left text-black400 p-2">
                      {/* <button className=" text-gray700 text-sm py-1 font-medium px-5 rounded border-primary border-solid border cursor-pointer flex justify-center" onClick={() => handleDeleteButton(item)}>
                        Delete
                      </button> */}
                      <div onClick={() => handleDeleteButton(item)}>
                        <i class="fa-solid fa-trash text-red-600 cursor-pointer"></i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center mobile:gap-2 min-h-[calc(100vh-315px)] mobile:grid mobile:grid-cols-1 p-3 rounded-[20px] w-full ">
              <div>
                <div className="flex items-center justify-center">
                  <img src={NoDataFound} alt="No Data Found" width={200} height={200} />
                </div>
                <p className="text-xl font-medium text-black mobile:text-lg text-center">No transaction found</p>
                <p className="text-center mt-2 mobile:text-sm">You have not any transaction yet</p>
              </div>
            </div>
          </>
        )}
      </div>
      <Pagination pages={Math.ceil(allUserTransactionsCount / 10)} current={page} onClick={setPage} />

      {deleteModel?.isShow && <DeleteTransactionModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDeleteSubscription={handleDeleteSubscription} />}
      {deleteTransactionLoader && <FullPageLoader />}
    </>
  );
}

export default Transactions;
