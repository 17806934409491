export default {
  id: 1,
  planTitle: "Premium",
  isSaving: true,
  savings: "20%",
  fees: true,
  planprevfeesMonthly: "$7.99/ m",
  planfeesMonthly: "$4.99/ m",
  costs: "4.99",
  annualbill: "$59.88 billed annually",
  planFeatures: [
    {
      feature: "Basic Tracking",
    },
    {
      feature: "Calendar View",
    },
    {
      feature: "Recommended Subs",
    },
    {
      feature: "Price Match",
    },
    {
      feature: "Extension",
    },
    {
      feature: "Projected Spending",
    },
    {
      feature: "Alladdin DB",
    },
    {
      feature: "Alerts (In App + Email)",
    },
    {
      feature: "Advanced Reporting",
    },
  ],
  buttonText: "Start Trial",
};
